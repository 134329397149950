import { Component, Input, OnInit, inject } from '@angular/core';

import { Observable } from 'rxjs';
import { ModuleInfoService } from '../services/module-info.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'orgbrain-info-box',
  template: `
    <div
      [ngClass]="
        simplecss
          ? 'd-flex px-3 py-3 rounded-md orgbrain-info-box-box m-w-50 m-w-100-sm'
          : 'd-flex orgbrain-info-box-box ptx-1 px-1 pbx-4 rounded-lg'
      "
      style="font-size: 0.875rem"
      *ngIf="(closed$ | async) !== true"
    >
      <div class="d-flex flex-grow-1" [ngClass]="simplecss ? '' : 'ptx-3 px-3'">
        <div class="d-flex items-start" [ngClass]="simplecss ? 'mrx-2' : 'mrx-4'">
          <mat-icon style="color: #0097a7">info_outlined</mat-icon>
        </div>
        <div [ngStyle]="simplecss ? { color: '#546e7a' } : { color: '#546e7a' }">
          <ng-content></ng-content>
        </div>
      </div>

      <div class="d-flex items-start">
        <mat-icon
          *ngIf="moduleId"
          class="module-info-close cursor-pointer rounded-full"
          (click)="close()"
          >close</mat-icon
        >
      </div>
    </div>
  `,
  styles: [
    `
      .module-info-close:hover {
        background-color: #e0e0e0;
      }
    `,
  ],
})
export class InfoBoxComponent implements OnInit {
  moduleInfoService: ModuleInfoService = inject(ModuleInfoService);
  amIClosed = false;
  @Input() simplecss = false;
  @Input() beginClosedState = false;
  @Input() moduleId: string;
  closed$: Observable<boolean>;

  ngOnInit() {
    if (!this.moduleId) {
      return;
    }
    this.closed$ = this.moduleInfoService.closed(this.moduleId, this.beginClosedState);
    this.closed$.pipe(untilDestroyed(this)).subscribe((val) => {
      // setTimeout is to avoid changed after checked error
      setTimeout(() => {
        this.amIClosed = val;
      }, 0);
    });
  }

  close() {
    this.moduleInfoService.close(this.moduleId);
  }
}
