export enum OrgbrainErrorCodes {
  NOT_ENOUGH_SHARES = 'NOT_ENOUGH_SHARES',
  BELOW_ZERO = 'BELOW_ZERO',
  SHARE_AMOUNT_MUST_BE_INTEGER = 'SHARE_AMOUNT_MUST_BE_INTEGER',
  // SHARE_NUMBERS_INCONSISTANT = 'SHARE_NUMBERS_INCONSISTANT',
  // NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS = 'NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS',
  NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_TRIED_REVERSING = 'NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_TRIED_REVERSING',
  NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_NULL = 'NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_NULL',
  NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_TO_GREATER_THEN_FROM = 'NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_TO_GREATER_THEN_FROM',
  NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_NAN = 'NOTE_CANNOT_BE_CONVERTED_TO_NUMBERS_NAN',
  SHARE_NUMBERS_INCONSISTANT_RECORD_WITHOUT_NUMBERS = 'SHARE_NUMBERS_INCONSISTANT_RECORD_WITHOUT_NUMBERS',
  SHARE_NUMBERS_INCONSISTANT_RECORD_AMOUNT_MISMATCH = 'SHARE_NUMBERS_INCONSISTANT_RECORD_AMOUNT_MISMATCH',
  SHARE_NUMBERS_INCONSISTANT_GAPS = 'SHARE_NUMBERS_INCONSISTANT_GAPS',
  SHARE_NUMBERS_INCONSISTANT_OVERLAP = 'SHARE_NUMBERS_INCONSISTANT_OVERLAP',
}
