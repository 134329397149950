// Stuff that paul could not cope with has been put here

import { _defaultRoleMap } from './defaultRoleMap';
import { hasPermission } from './hasPermission';
import { rolesPriorityArray } from './priorities';
import {
  BooleanMap,
  permissionDescription,
  PermissionEnum,
  reverseMapGroupKeyToPermission,
  Role,
  RoleMapType,
} from './rolesAndPermissions';

export const sortByRoleHelper = ({
  roles,
  email,
  firstName,
}: {
  roles: string[];
  email?: string;
  firstName?: string;
}): number => {
  // Sorting by ROLE as described here: https://fintechneo.atlassian.net/browse/OR-686
  roles = Array.isArray(roles) ? roles : [];
  const rolesMap = {};

  for (const role of roles) {
    rolesMap[role] = true;
  }
  if (firstName && !email) {
    // to top up users without email...
    // this is for admin app
    return 5000;
  }
  for (let i = 0; i < rolesPriorityArray.length; i++) {
    if (rolesMap[rolesPriorityArray[i]]) {
      return 1000 / (i === 0 ? 0.5 : i); // Trick to range CEO higher then CHAIR etc...
    }
  }

  return 0; // No role - no respect
};

export type RoleByTime = {
  _id?: string;
  role: Role;
  grantedAt: string;
  withdrawnAt: string;
  grantedBy: string;
  withdrawnBy: string;
};

export enum ClientUserRole {
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CLIENT_SALES = 'CLIENT_SALES',
}

export enum RoleContext {
  MEETING_APP = 'MEETING_APP',
  MEETING_PROTOCOL = 'MEETING_PROTOCOL',
  GA_MEETING_PROTOCOL = 'GA_MEETING_PROTOCOL',
}

export enum permisionGroupsAndRolesEnum {
  BOARD = 'BOARD',
  SHAREHOLDERS = 'SHAREHOLDERS',
}

export const permisionGroupsAndRoles = {
  [permisionGroupsAndRolesEnum.BOARD]: [PermissionEnum.ACCESS_TO_NEWS_FOR_BOARD],
  [permisionGroupsAndRolesEnum.SHAREHOLDERS]: [PermissionEnum.ACCESS_TO_NEWS_FOR_SHAREHOLDERS],
};

export function getFriendlyPermissionsString(permissions: { [key: string]: boolean }) {
  return Object.keys(permissions).join(' ');
}

export function rolesByTimeToRolesHelper({
  roles,
  rolesByTime,
  timestamp,
}: {
  roles: string[];
  rolesByTime: RoleByTime[];
  timestamp?: string;
}) {
  roles = roles || [];
  rolesByTime = rolesByTime || [];
  let now: any = Date.now();
  // 13 Is a length of UNIX timestamp. TODO: fix it after 20 Nov 2286 (timestamp becomes 14 length)
  if (timestamp && timestamp.length === 13 && !isNaN(+timestamp)) {
    now = +timestamp;
  }
  const availableFromRolesByTime = {};
  const allRolesByTime = {};

  for (const role of rolesByTime) {
    allRolesByTime[role.role] = true;
    let withdrawnTimestamp: any = role.withdrawnAt;
    let grantedTimestamp: any = role.grantedAt;
    if (typeof withdrawnTimestamp === 'string') {
      withdrawnTimestamp = new Date(withdrawnTimestamp).getTime();
    }
    if (typeof grantedTimestamp === 'string') {
      grantedTimestamp = new Date(grantedTimestamp).getTime();
    }
    // First - create map of roles that are allowed by their time frame
    const ifBefore = grantedTimestamp <= now;

    const isAfter = !withdrawnTimestamp || withdrawnTimestamp > now;

    if (ifBefore && isAfter) {
      availableFromRolesByTime[role.role] = true;
    }
  }

  // Second - for legacy reasons where no time frame is set - assume the role is forever
  for (const role of roles) {
    if (!allRolesByTime[role]) {
      availableFromRolesByTime[role] = true;
    }
  }

  return Object.keys(availableFromRolesByTime) as Role[];
}

// Return union of 2 sets
export function filterTruthMap(a: BooleanMap, b: BooleanMap): BooleanMap {
  const ret = {};

  for (const key of Object.keys(a)) {
    if (b[key]) {
      ret[key] = true;
    }
  }
  return ret;
}

export function filterRoleMap({
  roleMap,
  availablePermissionsMap,
  availableRolesMap,
}: {
  roleMap: RoleMapType;
  availablePermissionsMap;
  availableRolesMap;
}): RoleMapType {
  const roles = Object.keys(availableRolesMap);

  const newRoleMap: RoleMapType = {} as RoleMapType;
  for (const role of roles) {
    const newPerms = filterTruthMap(roleMap[role], availablePermissionsMap);
    newRoleMap[role] = newPerms;
  }

  return newRoleMap;
}

export function hasPaidRoles(roles: Role[]) {
  if (Array.isArray(roles) && roles.length) {
    const map = {};
    for (const role of roles) {
      map[role] = role;
    }

    const paidRoles = [
      Role.ADMIN,
      Role.USER_ADMIN,
      Role.CHAIR,
      Role.BOARDMEMBER,
      Role.CEO,
      Role.CHAIR,
      Role.BOARDSECRETARY,
      Role.DEPUTYCHAIR,
      Role.DEPUTYMEMBER,
    ];

    // free roles?
    // OBSERVER
    // SHAREHOLDER
    // AUDITOR
    // CONTACTPERSON
    // SHAREHOLDER_PROXY
    // BUYER
    // SCHOOL

    for (const paidRole of paidRoles) {
      if (map[paidRole]) {
        return true;
      }
    }
  }

  return false;
}

export const belongsToGroup = function (
  permKey: permisionGroupsAndRolesEnum,
  roles: Role[],
  roleMap
) {
  const hasPerm = !!permisionGroupsAndRoles[permKey].find((perm) =>
    hasPermission(perm, roles, roleMap)
  );

  return hasPerm;
};

export const doesRolesIncludeAtLeastOneOfThePermissions = function (
  roles: Role[],
  permissions: PermissionEnum[],
  roleMap: RoleMapType
) {
  for (const perm of permissions) {
    if (hasPermission(perm, roles, roleMap)) {
      return true;
    }
  }

  return false;
};

export const rolesToPermissionMap = function (
  roles: Role[],
  roleMap: RoleMapType
): { [perm: string]: boolean } {
  const map: { [perm: string]: boolean } = {};
  for (const role of roles) {
    if (roleMap[role]) {
      for (const d of Object.keys(permissionDescription)) {
        if (roleMap[role][d]) {
          map[d] = true;
        }
      }
    }
  }
  return map;
};

/**
 *
 * create group post-fixes   given an array of user roles
 *
 *
 */

export const rolesToGroupPostFixArray = function (
  roles: Role[],
  roleMap: RoleMapType
): Array<string> {
  const map: { [perm: string]: boolean } = {};
  for (const role of roles) {
    if (roleMap[role]) {
      //This could possibly be wayyy faster by looping thorugh the keys in rolemap and not permissiondesc
      for (const d of Object.keys(permissionDescription)) {
        if (roleMap[role][d]) {
          map[d] = true;
        }
      }
    }
  }

  const groups = [];

  Object.keys(map).forEach((key) => {
    const group = permissionDescription[key].group;
    if (group) {
      groups.push(group);
    }
  });
  return groups;
};

/***
 *
 * Create groups for a user given an organization and roles
 *
 */

export const rolesToOrgGroupArray = function ({
  organizationId,
  sharedFolderOrganizationIds,
  roles,
  userId,
  hasWritePermission,
  roleMap,
}: {
  organizationId: string;
  sharedFolderOrganizationIds?: string[];
  hasWritePermission?: boolean;
  roles: Role[];
  userId: string;
  roleMap: RoleMapType;
}): Array<string> {
  const list: Array<string> = [organizationId];
  if (userId) {
    list.push(`${organizationId}-${userId}`);
  }

  //  TODO FILES  we could have organization groups per user here (if we don't want to keep adding to Permissions)
  const beforeMapping = rolesToGroupPostFixArray(roles, roleMap);

  const afterMapping = [];
  for (const x of beforeMapping) {
    afterMapping.push(`${organizationId}-${x}`);
    if (sharedFolderOrganizationIds) {
      for (const sharedFolderOrganizationId of sharedFolderOrganizationIds) {
        if (x === 'admin') {
          if (hasWritePermission) {
            afterMapping.push(`${sharedFolderOrganizationId}-${x}`);
          }
        } else {
          afterMapping.push(`${sharedFolderOrganizationId}-${x}`);
        }
      }
    }
  }

  const roleGroups = afterMapping;

  return list.concat(roleGroups);
};

export function createAllPermissionGroupsList(organizationId: string) {
  const groups: string[] = [];

  for (const key of Object.keys(permissionDescription)) {
    const perm = permissionDescription[key];
    if (perm.group) {
      groups.push(`${organizationId}-${perm.group}`);
    }
  }

  return groups;
}

/**
 *
 * Create group given organization and permission
 *
 *
 */
export const permissionToOrgGroup = function ({
  organizationId,
  perm,
}: {
  organizationId: string;
  perm: PermissionEnum;
}): string {
  const group = permissionDescription[perm].group;
  if (!group) {
    throw new Error(`permissionToOrgGroup   ${perm}  has no group`);
  }
  return `${organizationId}-${group}`;
};

export function canDownloadZipWithoutWatermarks(
  roles: Role[],
  defaultFilePermissionsMap,
  roleMap: RoleMapType
): boolean {
  const canProceed = canDownloadFileWithoutWatermarks({
    defaultFilePermissionsMap,
    ignoreWatermarks: false,
    roleMap,
    roles,
  });

  return canProceed && hasPermission(PermissionEnum.DATAROOM_DOWNLOAD_ZIP, roles, roleMap);
}

function canDownloadFileWithoutWatermarksOld(
  defaultFilePermissionsMap,
  // When ignoreWatermarks is true, we only check for download.
  // If it's false, we check that watermarks are not enabled
  ignoreWatermarks: boolean
): boolean {
  const map = defaultFilePermissionsMap;
  let canProceed = true;
  if (!map) {
    canProceed = true;
  } else {
    for (const key of Object.keys(map)) {
      if (map[key].download === true) {
        if (!ignoreWatermarks) {
          canProceed = canProceed && map[key].watermark === false;
        }
      } else {
        canProceed = false;
      }
    }
  }

  return canProceed;
}

export function canDownloadFileWithoutWatermarks({
  defaultFilePermissionsMap,
  ignoreWatermarks,
  roleMap,
  roles,
}: {
  defaultFilePermissionsMap;
  // When ignoreWatermarks is true, we only check for download.
  // If it's false, we check that watermarks are not enabled
  ignoreWatermarks: boolean;
  roleMap;
  roles: Role[];
}): boolean {
  roleMap = roleMap || _defaultRoleMap;
  const map = defaultFilePermissionsMap;
  let canProceed = false;
  if (!map) {
    canProceed = true;
  } else {
    for (const key of Object.keys(map)) {
      const hasPermForCurrentKey = doesRolesIncludeAtLeastOneOfThePermissions(
        roles,
        reverseMapGroupKeyToPermission[key],
        roleMap
      );

      if (!canProceed) {
        if (map[key].download === true) {
          if (!ignoreWatermarks) {
            canProceed = hasPermForCurrentKey && map[key].watermark === false;
          } else {
            canProceed = true;
          }
        }
      }
    }
  }

  // we also run to check for canDownloadFileWithoutWatermarksOld in situation where it is not set to watermark anyone,
  // but current user has specific role (not admin, not buyer etc)
  return canProceed || canDownloadFileWithoutWatermarksOld(map, ignoreWatermarks);
}

export function cloneRoleMap(map: RoleMapType): RoleMapType {
  const clone: RoleMapType = {} as any;

  for (const role of Object.keys(map)) {
    clone[role] = {};
    for (const perm of Object.keys(map[role])) {
      clone[role][perm] = map[role][perm];
    }
  }
  return clone;
}

export function applyRoleMapTweaks({
  roleMap,
  roleMapTweaks,
  logger,
}: {
  roleMap: RoleMapType;
  roleMapTweaks: RoleMapType;
  logger: any;
}) {
  const newRoleMap = cloneRoleMap(roleMap);

  for (const role of Object.keys(roleMapTweaks)) {
    for (const perm of Object.keys(roleMapTweaks[role])) {
      const tweak = roleMapTweaks[role][perm];
      try {
        if (tweak) {
          newRoleMap[role][perm] = true;
        } else {
          delete newRoleMap[role][perm];
        }
      } catch (error) {
        if (logger) {
          logger.log({
            context: 'BUG IN applyRoleMapTweaks()',
            error,
          });
        }
      }
    }
  }
  return newRoleMap;
}

export function roleMapDelta({
  roleMap,
  fullRoleMap,
}: {
  roleMap: RoleMapType;
  fullRoleMap: RoleMapType;
}) {
  const newRoleMapDelta = {} as RoleMapType;

  for (const role of Object.keys(fullRoleMap)) {
    newRoleMapDelta[role] = {};

    for (const perm of Object.keys(fullRoleMap[role])) {
      if (!roleMap[role] || !roleMap[role][perm]) {
        newRoleMapDelta[role][perm] = true;
      }
    }
  }

  return newRoleMapDelta;
}
