import { CompanyTerminologyEnum } from './companyTypesWithTypeChecking';
import { permissionDescription, PermissionEnum } from './rolesAndPermissions';

export const permissionTranslationTextMap: {
  [key in PermissionEnum]?: {
    [orgtype in CompanyTerminologyEnum]?: { en: string; nb: string; sv: string };
  };
} = {
  shareholders: {
    BRL: {
      en: 'Members APP ',
      nb: 'Members APP NB',
      sv: 'MemberSV',
    },
  },
};

for (const perm of Object.keys(permissionDescription)) {
  const desc = permissionDescription[perm];
  permissionTranslationTextMap[perm] = permissionTranslationTextMap[perm] || {};
  permissionTranslationTextMap[perm].default =
    permissionTranslationTextMap[perm].default || selectLangs(desc);
}

export const permissionToolTipTranslationTextMap: {
  [key in PermissionEnum]: {
    [orgtype in CompanyTerminologyEnum]?: { en: string; nb: string; sv?: string };
  };
} = {
  shareholders: {
    BRL: {
      en: 'Members APP ',
      nb: 'Members APP NB',
      sv: 'MemberSV',
    },
  },
  ADMIN: {
    default: {
      nb: 'Administrator (Master) har administratorrettigheter til alle funksjoner i portalen, herunder å legge til nye brukere og slette brukere. ',
      en: 'Administrator (Master) has administrator rights to all functions in the portal, including adding new users and deleting users. ',
    },
  },
  [PermissionEnum.MEETING_ADMIN]: undefined,
  [PermissionEnum.ORGANIZATION_ADMIN]: undefined,
  [PermissionEnum.GA_MEETING_ADMIN]: undefined,
  [PermissionEnum.BOARD_MEETING_ADMIN]: undefined,
  [PermissionEnum.TASKS_ADMIN]: undefined,
  [PermissionEnum.SHAREHOLDER_ADMIN]: undefined,
  [PermissionEnum.NEWS_ADMIN]: undefined,
  [PermissionEnum.GA_NEWS_ADMIN]: undefined,
  [PermissionEnum.CAN_VOTE]: undefined,
  [PermissionEnum.CAN_VOTE_BOARDMEETING]: undefined,
  [PermissionEnum.ACCESS_TO_BOARD_MEETINGS]: undefined,
  [PermissionEnum.ACCESS_TO_GA_MEETINGS]: undefined,
  [PermissionEnum.DATAROOM_DOWNLOAD_ZIP]: undefined,
  [PermissionEnum.ORGBRAIN_ACADEMY]: undefined,
  [PermissionEnum.DATAROOM_SEE_SHOW_DELETED]: undefined,
  [PermissionEnum.DATAROOM_COPY_FILE]: undefined,
  [PermissionEnum.DATAROOM_SEE_FILE_DETAILS]: undefined,
  [PermissionEnum.DATAROOM_SEE_FILE_HISTORY]: undefined,
  [PermissionEnum.DATAROOM_CREATE_TASK_FROM_FILE]: undefined,
  [PermissionEnum.DATAROOM_SEE_FILE_MENU]: undefined,
  [PermissionEnum.DATAROOM_SET_FILE_STATUS]: undefined,
  [PermissionEnum.SHAREHOLDER]: undefined,
  [PermissionEnum.OWNER]: undefined,
  [PermissionEnum.VIEW_SHARES]: undefined,
  [PermissionEnum.CREATE_SIGNING_ORDER]: {
    default: {
      nb: 'Denne tilgangen gir brukeren mulighet til å opprette elektronisk signatur på et PDF-dokument.',
      en: 'This access gives the user the ability to create electronic signature on a PDF document.',
    },
  },

  [PermissionEnum.SEE_CYCLIC_CALENDAR]: undefined,
  [PermissionEnum.SEE_FOLLOWUP_CASES]: undefined,
  [PermissionEnum.SEE_USER_EMAILS]: undefined,
  [PermissionEnum.SEE_MANAGE_SIGNATURES]: {
    default: {
      nb: 'Denne tilgangen gir brukeren mulighet til å se signaturstatus på alle dokumenter der det er opprettet elektronisk signatur, herunder hvem som er invitert til å signere. ',
      en: 'This access gives the user the ability to see signature status on all documents where electronic signature has been created, including who has been invited to sign. ',
    },
  },

  [PermissionEnum.SEE_USER_NAMES]: undefined,
  [PermissionEnum.SEE_ACTION_POINTS]: undefined,
  [PermissionEnum.SEE_ENTITY_SENSITIVE]: undefined,
  [PermissionEnum.BUYER]: undefined,
  [PermissionEnum.SELLER]: undefined,
  [PermissionEnum.CREATE_DD_GROUP]: undefined,
  [PermissionEnum.CREATE_SUB_GROUP]: undefined,
  [PermissionEnum.ACCESS_TO_SECURITY_TAB]: undefined,
  [PermissionEnum.ACCESS_TO_SETTINGS_TAB]: undefined,
  [PermissionEnum.BUYER_ADMIN]: undefined,
  [PermissionEnum.VIEW_SHARE_ISSUES]: undefined,
  [PermissionEnum.INITIATE_ORGBRAIN_SIGNING]: undefined,
  [PermissionEnum.INITIATE_BANK_ID_SIGNING]: undefined,
  [PermissionEnum.SELLER_ADMIN]: undefined,
  [PermissionEnum.ACCESS_TO_NEWS_FOR_BOARD]: undefined,
  [PermissionEnum.ACCESS_TO_NEWS_FOR_SHAREHOLDERS]: undefined,
  [PermissionEnum.templates]: undefined,
  [PermissionEnum.files]: {
    default: {
      nb: ' Denne gir deg tilgang til datarommet. Brukeren vil kun få tilgang til mapper som er tilknyttet rollen som brukeren har i organisasjon. ',
      en: ' This gives you access to the data room. The user will only have access to folders that are associated with the role that the user has in the organization. ',
    },
  },
  [PermissionEnum.news]: undefined,
  [PermissionEnum.surveys]: undefined,
  [PermissionEnum.meetings]: undefined,
  [PermissionEnum.tasks]: undefined,
  [PermissionEnum.school]: {
    default: {
      nb: 'Brukeren får tilgang til Styreskolen, som er et nettbasert sertifiseringskurs i styrearbeid.',
      en: 'The user gets access to the Board School, which is an online certification course in board work.',
    },
  },
  [PermissionEnum.chat]: {
    default: {
      nb: ' Brukeren får tilgang til organisasjonens chatfunksjon.',
      en: ' The user gets access to the organization’s chat function.',
    },
  },
  [PermissionEnum.organization]: undefined,
  [PermissionEnum.TEMPLATES_ADMIN]: undefined,
  [PermissionEnum.DATAROOM_ADMIN]: undefined,
  [PermissionEnum.OPENAI_USER]: undefined,
  [PermissionEnum.BOARDMATCH_ADMIN]: undefined,
  [PermissionEnum.CONVERSATIONS_ADMIN]: undefined,
  [PermissionEnum.CONVERSATIONS_CREATE]: undefined,
  [PermissionEnum.CONVERSATIONS_LIMITED]: undefined,
};

for (const perm of Object.keys(permissionDescription)) {
  const desc = permissionDescription[perm];
  permissionToolTipTranslationTextMap[perm] = permissionToolTipTranslationTextMap[perm] || {};
  permissionToolTipTranslationTextMap[perm].default =
    permissionToolTipTranslationTextMap[perm].default || selectDescription(desc);
}

// console.log(JSON.stringify(permissionTranslationTextMap, null, 2));

function selectLangs(desc) {
  return { en: desc.en, nb: desc.nb, sv: desc.sv };
}

function selectDescription(desc) {
  return { en: desc.description, nb: desc.description, sv: desc.description };
}
